<template>
  <el-main class="rh-medform">
    <form-evaluationcreate :routes="routes" />
  </el-main>
</template>
<script>
import FormEvaluationcreate from "@/components/rh/formulaires/FormEvaluationcreate";

export default {
  components: { "form-evaluationcreate": FormEvaluationcreate },
  computed: {
    routes() {
      const path = this.$route.path;
      const routeTutorat = path.includes("tuteurs")
        ? "med_tuteurs_edit"
        : "med_pupilles_edit";
      const routeEval = path.includes("tuteurs")
        ? "med_tut_evaluations_edit"
        : "med_pup_evaluations_edit";

      return {
        toedit: {
          name: routeEval,
          params: { ideval: this.$store.state.eva.evaluationLastCreatedId },
        },
        tolist: {
          name: routeTutorat,
          params: {
            idmed: this.$route.params.idmed,
            idtutorat: this.$route.params.idtutorat,
          },
        },
      };
    },
  },
};
</script>
